import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { componentColors, fontSize } from '../../theme';

interface PageContainerProps {
    isMobile?: boolean;
}

export const PageContainer = styled(Box) <PageContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${componentColors.pageBackground};
    color: ${componentColors.pageContrast};
    padding: ${({ isMobile }) => (isMobile ? "16px 8px 16px" : "24px")};
    font-size: ${({ isMobile }) => (isMobile ? `${fontSize.mobile}px` : `${fontSize.default}px`)};
`

export const SocialIconsContainer = styled(Box)`
    display: flex; 
    width: 120px; 
    justify-content: space-between; 
    padding-top: 16px;
`