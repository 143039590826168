import { doc, getDoc } from "firebase/firestore";
import { firestoreDB } from "../configuration/firebase";
import { CurrentEvents, RankedStats, Event, RankedStatsId } from "./ranked-stats.types";

const COLLECTION_RANKED = "ranked"
const DOCUMENT_CURRENT_EVENTS = "current-events"
const DOCUMENT_EVENT_PREFIX = "event-"

export const getRankedStats = async (id: RankedStatsId): Promise<RankedStats> => {
  const rankedStats = true ? await getRankedStatsFromFirestore(id) : getRankedStatsFromAPI(id)
  return rankedStats
};

const getRankedStatsFromAPI = async (id: RankedStatsId): Promise<RankedStats> => {
  const response = await fetch(`http://localhost:8000/stats/ranked/${id}`, {});
  if (response.ok) {
    return response.json();
  } else {
    const responseError = await response.json();
    const error = new Error(responseError);
    throw error;
  }
};

const getRankedStatsFromFirestore = async (id: RankedStatsId): Promise<RankedStats> => {
  const docRefCurrentEvents = doc(firestoreDB, COLLECTION_RANKED, DOCUMENT_CURRENT_EVENTS);
  const querySnapshotCurrentEvents = await getDoc(docRefCurrentEvents);
  const currentEvents = querySnapshotCurrentEvents.data() as CurrentEvents;
  const docRefEvent = doc(firestoreDB, COLLECTION_RANKED, DOCUMENT_EVENT_PREFIX + id);
  const querySnapshotEvent = await getDoc(docRefEvent);
  const event = querySnapshotEvent.data() as Event;
  const rankedStats: RankedStats = { currentEvents: currentEvents.currentEvents, event: event }
  return rankedStats
};