import styled from '@emotion/styled';
import { Box, TableCell, TableSortLabel } from '@mui/material';
import { componentColors, componentStyles, primaryPalette } from '../../../theme';

export const HintMapText = styled.span`
  color: ${componentColors.linkText};
`;

export const HintModeText = styled.span`
  color: ${componentColors.secondaryHintText};
`;

interface TableCellStyledProps {
  isMobile?: boolean;
}

export const TableCellStyled = styled(TableCell) <TableCellStyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ isMobile }) => (isMobile ? "2px" : "8px")};
  font-size: 1em;
`

export const TableCellName = styled(TableCellStyled) <TableCellStyledProps>`
  max-width: ${({ isMobile }) => (isMobile ? "48px" : "74px")};
`

interface TableCellIconStyledProps {
  isMobile?: boolean;
}

export const TableCellIconStyled = styled(TableCell) <TableCellIconStyledProps>`
  padding: ${({ isMobile }) => (isMobile ? "4px" : "8px")};
`

export const TableSortLabelStyled = styled(TableSortLabel)`
  white-space: nowrap;
`

export const CardTableContainer = styled(Box)`
  background-color: ${componentColors.card};
  border: 1px solid ${componentColors.cardBorder};
  border-radius: ${componentStyles.cardRadius};
  overflow: hidden;
`

interface CardFirstSectionContainerProps {
  isMobile?: boolean;
}

export const CardFirstSectionContainer = styled(Box) <CardFirstSectionContainerProps>`
  padding: ${({ isMobile }) => (isMobile ? "16px 8px 16px" : "16px")};
`

interface CardSecondSectionContainerProps {
  isMobile?: boolean;
}

export const CardSecondSectionContainer = styled(Box) <CardSecondSectionContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${primaryPalette[500]};
  padding: ${({ isMobile }) => (isMobile ? "16px 8px 16px" : "16px")};
`

export const ShareButtonsContainer = styled(Box)`
  flex-shrink: 0;
  padding-left: "4px";
`
