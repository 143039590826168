import { componentColors, primaryPalette } from './theme';
import GlobalStyles from '@mui/material/GlobalStyles';


export const GlobalStylesCustom = () => (
  <GlobalStyles
    styles={{
      '*::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: `${primaryPalette[300]}`,
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: `${primaryPalette[400]}`,
      },
      '*::-webkit-scrollbar-track': {
        background: `${componentColors.pageBackground}`,
      },
      '*': {
        scrollbarWidth: '12px',
        scrollbarColor: `${primaryPalette[300]} ${componentColors.pageBackground}`,
      },
    }}
  />
);