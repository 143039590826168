import { BrawlerStats } from "../../../api/ranked-stats.types";

export enum Order {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export interface Column {
    title: string;
    key: keyof BrawlerStats;
    tooltip?: string;
}

export enum ShareType {
    DEFAULT = 'DEFAULT',
    X = 'X',
    REDDIT = 'REDDIT'
}
