import { Box, Link, Typography } from "@mui/material";
import { componentColors } from "../../theme";
import { useTranslation } from "react-i18next";

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <Typography component="footer"> 
                {t("COMPONENTS.FOOTER.UNAFFILIATED_CONTENT")} <br />
                {t("COMPONENTS.FOOTER.MORE_INFO")}
                <Link href="https://supercell.com/en/fan-content-policy/" color={componentColors.linkText} underline="hover" target="_blank">
                    Supercell's Fan Content Policy
                </Link>
            </Typography>
        </Box>
    );
}
