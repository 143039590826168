import { CurrentEvent, RankedStatsId } from "../../../api/ranked-stats.types";
import { RankedMaps, Map } from "./rankeds-maps.types";

export const transformToRankedMaps = (events: CurrentEvent[]): RankedMaps[] => {
    const groupedByMode: { [key: string]: Map[] } = {};

    events.forEach(event => {
        if (!groupedByMode[event.mode]) {
            groupedByMode[event.mode] = [];
        }
        groupedByMode[event.mode].push({ id: event.id.toString() as RankedStatsId, name: event.name });
    });

    return Object.keys(groupedByMode).map(mode => ({
        mode,
        maps: groupedByMode[mode]
    }));
}
