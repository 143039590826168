import Box from "@mui/material/Box";
import { ALL_ID, CurrentEvent, RankedStatsId } from "../../../api/ranked-stats.types";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AllMapsContainer, MapContainerBtn, ModeContainer, ModeTitle, AllMapsTextContainer, TitleContainer, ImageMapContainer, MapContainer, ModeTitleContainer, ModeImage } from "./rankeds-maps.styles";
import { RankedMaps } from "./rankeds-maps.types";
import { transformToRankedMaps } from "./rankeds-maps.functions";
import { useTranslation } from "react-i18next";
import { battleModeColors } from "../../../theme";

interface Props {
  selectedMapId: RankedStatsId;
  currentEvents: CurrentEvent[];
  onClickMap: (eventId: RankedStatsId) => void;
}

export const RankedsMaps: React.FC<Props> = ({
  selectedMapId,
  currentEvents,
  onClickMap
}: Props) => {
  const { t } = useTranslation();
  const [rankedMaps, setRankedMaps] = useState<RankedMaps[]>([]);

  useEffect(() => {
    const eventsTransformed = transformToRankedMaps(currentEvents)
    setRankedMaps(eventsTransformed)
  }, [currentEvents]);

  return (
    <Box>
      <AllMapsContainer>
        <MapContainerBtn
          onClick={() => onClickMap(ALL_ID)}
          isSelected={ALL_ID === selectedMapId}
          role="button"
          tabIndex={0}
          aria-label={t("RANKEDS_PAGE.MAPS.ALL_MAPS")}>
          <AllMapsTextContainer>
            <Typography component="h3">
              {t("RANKEDS_PAGE.MAPS.ALL_MAPS")}
            </Typography>
          </AllMapsTextContainer>
        </MapContainerBtn>
      </AllMapsContainer>
      {rankedMaps.map((mode) => (
        <Box key={mode.mode}>
          <ModeTitleContainer>
            <ModeImage
              component="img"
              alt={`${mode.mode} Logo`}
              modeColor={battleModeColors[mode.mode as keyof typeof battleModeColors]}
              src={`/images/modes/${mode.mode}.webp`}
            />
            <ModeTitle component="h3" modeColor={battleModeColors[mode.mode as keyof typeof battleModeColors]}>
              {t(`DOMINE.MODE.${mode.mode}`).toUpperCase()}
            </ModeTitle>
          </ModeTitleContainer>
          <ModeContainer>
            {mode.maps.map((event) => (
              <MapContainer
                key={event.id}
                onClick={() => onClickMap(event.id)}
                isSelected={event.id.toString() === selectedMapId}
                role="button"
                tabIndex={0}
                aria-label={t("RANKEDS_PAGE.MAPS.MAP") + event.name}>
                <TitleContainer>
                  <Typography>
                    {event.name}
                  </Typography>
                </TitleContainer>
                <ImageMapContainer
                  component="img"
                  alt={t("RANKEDS_PAGE.MAPS.MAP") + event.name}
                  src={`/images/maps/${event.id}.webp`}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = '/images/maps/unknown.webp';
                  }}
                />
              </MapContainer>
            ))}
          </ModeContainer>
        </Box>
      ))}
    </Box>
  );
}
