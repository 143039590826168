import { NavBar } from "./routes/nav-bar/nav-bar";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/routes";
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import "./i18n";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { analytics } from './configuration/firebase';
import { GlobalStylesCustom } from "./global-styles";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStylesCustom/>
      <BrowserRouter>
        <NavBar>
          <AppRoutes />
        </NavBar>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
