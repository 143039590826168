import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DEFAULT_LANGUAGE, Languages } from "../../hooks/language/language-hook.types";
import { useLanguage } from "../../hooks/language/language-hook";

export const LanguageRouter: React.FC = () => {
    const currentLocation = useLocation();
    const { setLanguage, isSupportedLang, getLanguage } = useLanguage();
    const navigate = useNavigate();

    const firstSegment = currentLocation.pathname.split('/')[1];

    useEffect(() => {
        const currentLanguage = getLanguage()
        if (firstSegment === "" && getLanguage() !== DEFAULT_LANGUAGE) {
            const newPath = `/${currentLanguage}${currentLocation.search}`;
            navigate(newPath)
        }
        else if (isSupportedLang(firstSegment)) {
            setLanguage(firstSegment as Languages)
        } else {
            setLanguage(DEFAULT_LANGUAGE)
        }
    }, [currentLocation.search, firstSegment, setLanguage, isSupportedLang, getLanguage, navigate]);

    return <Outlet />;
};